interface MatterIdFilter {
  type: 'matter_id';
  operator: 'in';
  values: string[];
}

interface MyMattersFilter {
  type: 'my_matters';
  operator: 'in';
}

interface LitigationTypeFilter {
  type: 'matter_litigation_type';
  operator: 'in';
  values: string[];
}

interface CaseTypeFilter {
  type: 'matter_case_type';
  operator: 'in';
  values: string[];
}

interface CaseNameFilter {
  type: 'matter_case_name';
  operator: 'in';
  values: string[];
}

interface ReferenceNumberFilter {
  type: 'matter_reference_number';
  operator: 'in';
  values: string[];
}

interface ParentOfficeFilter {
  type: 'matter_parent_office';
  operator: 'in';
  values: string[];
}

interface CaseStatusFilter {
  type: 'matter_case_status';
  operator: 'in';
  values: string[];
}

interface MatterStageFilter {
  type: 'matter_stage_read_only';
  operator: 'in';
  values: string[];
}

interface DocumentIdFilter {
  type: 'document_id';
  operator: 'in';
  values: string[];
}

interface DocumentTypeFilter {
  type: 'document_type';
  operator: 'in';
  values: string[];
}

interface FileTypeFilter {
  type: 'document_file_type';
  operator: 'in';
  values: string[];
}

interface FileNameFilter {
  type: 'document_file_name';
  operator: 'contains';
  value: string;
}

interface ContentFilter {
  type: 'content';
  operator: 'match';
  value: string;
}

export type SearchFilter =
  | MatterIdFilter
  | MyMattersFilter
  | LitigationTypeFilter
  | CaseTypeFilter
  | CaseNameFilter
  | ReferenceNumberFilter
  | ParentOfficeFilter
  | CaseStatusFilter
  | MatterStageFilter
  | DocumentIdFilter
  | DocumentTypeFilter
  | FileTypeFilter
  | FileNameFilter
  | ContentFilter;

export const generateQuery = (
  litigation: string[],
  caseType: string[],
  caseStatus: string[],
  matterStage: string[],
  detectedType: string[],
  fileType: string[],
  myMatter: boolean,
  matterName: string[],
  matterId: string[],
  referenceNumber: string[],
  parentOffice?: string[],
  docId?: string,
  documentName?: string,
  search?: string,
  dateValue?: { operator: string; field: string; value: string }
): SearchFilter[] => {
  const filters: SearchFilter[] = [];

  if (matterId.length > 0) {
    filters.push({
      type: 'matter_id',
      operator: 'in',
      values: matterId,
    } as MatterIdFilter);
  }

  if (matterName.length > 0) {
    filters.push({
      type: 'matter_case_name',
      operator: 'in',
      values: matterName,
    } as CaseNameFilter);
  }

  if (referenceNumber.length > 0) {
    filters.push({
      type: 'matter_reference_number',
      operator: 'in',
      values: referenceNumber,
    } as ReferenceNumberFilter);
  }

  if (litigation.length > 0) {
    filters.push({
      type: 'matter_litigation_type',
      operator: 'in',
      values: litigation,
    } as LitigationTypeFilter);
  }

  if (caseType.length > 0) {
    filters.push({
      type: 'matter_case_type',
      operator: 'in',
      values: caseType,
    } as CaseTypeFilter);
  }

  if (caseStatus.length > 0) {
    filters.push({
      type: 'matter_case_status',
      operator: 'in',
      values: caseStatus,
    } as CaseStatusFilter);
  }

  if (matterStage.length > 0) {
    filters.push({
      type: 'matter_stage_read_only',
      operator: 'in',
      values: matterStage,
    } as MatterStageFilter);
  }

  if (parentOffice) {
    filters.push({
      type: 'matter_parent_office',
      operator: 'in',
      values: parentOffice,
    } as ParentOfficeFilter);
  }

  if (myMatter) {
    filters.push({
      type: 'my_matters',
      operator: 'in',
    } as MyMattersFilter);
  }

  if (docId) {
    filters.push({
      type: 'document_id',
      operator: 'in',
      values: [docId],
    } as DocumentIdFilter);
  }

  if (detectedType.length > 0) {
    filters.push({
      type: 'document_type',
      operator: 'in',
      values: detectedType,
    } as DocumentTypeFilter);
  }

  if (fileType.length > 0) {
    filters.push({
      type: 'document_file_type',
      operator: 'in',
      values: fileType,
    } as FileTypeFilter);
  }

  if (documentName) {
    filters.push({
      type: 'document_file_name',
      operator: 'contains',
      value: documentName,
    } as FileNameFilter);
  }

  if (search) {
    filters.push({
      type: 'content',
      operator: 'match',
      value: search,
    } as ContentFilter);
  }

  if (dateValue) {
    const { field, operator, value } = dateValue;

    filters.push({
      type: field,
      operator,
      value: value.format('YYYY-MM-DD'),
    });
  }

  return filters;
};
