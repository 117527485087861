import React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LoginIcon from '@mui/icons-material/Login';

import { useAuth } from '../App';

export default function LoginPage() {
  const { onLogin } = useAuth();
  return (
    <>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Typography variant="h3" color={'primary'}>
          Login
        </Typography>
      </Paper>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Button aria-label="login" color={'info'} onClick={onLogin} endIcon={<LoginIcon />}>
          Login
        </Button>
      </Paper>
    </>
  );
}
