import React from 'react';
import { createBrowserRouter, RouterProvider, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ToggleColorMode, useAuth } from './App';
import MyAccountPage from './pages/MyAccount';
import LoginPage from './pages/Login';
import LogoutPage from './pages/Logout';
import Chat from './pages/PromptSandbox';
import Forthepeople from './pages/Forthepeople';
import APIs from './pages/APIs';
import Viewer from './pages/Viewer';
import { EnterpriseSearch } from './pages/EnterpriseSearch/EnterpriseSearch';
import Tableau from './pages/Tableau';

const router = createBrowserRouter([
  {
    path: '*',
    Component: Root,
  },
]);

const Router = () => <RouterProvider router={router} />;
export default Router;

function Root() {
  return (
    <ToggleColorMode>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/logout"
          element={
            <ProtectedRoute>
              <LogoutPage />
            </ProtectedRoute>
          }
        />
        <Route path="/forthepeople" element={<Forthepeople />} />
        <Route
          path="/apis"
          element={
            <ProtectedRoute>
              <APIs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewer"
          element={
            <ProtectedRoute>
              <Viewer />
            </ProtectedRoute>
          }
        />
        <Route path="/doc-search" element={<DocSearchVercelRedirect />} />
        <Route path="/search" element={<DocSearchVercelRedirect />} />
        <Route
          path="/prompt-sandbox"
          element={
            <ProtectedRoute>
              <Navigate to="/" replace />
            </ProtectedRoute>
          }
        />
        <Route
          path="/expert-search"
          element={
            <ExpertSearchVercelRedirect/>
          }
        />
        <Route
          path="/tableau"
          element={
            <ProtectedRoute>
              <Tableau />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <MyAccountPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </ToggleColorMode>
  );
}

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { token } = useAuth();
  const location = useLocation();
  if (!token) {
    if (location.pathname !== '/login' && location.pathname !== '/logout' && location.pathname !== '/') {
      window.localStorage.setItem('pre_redirect_url', location.pathname + location.search);
    }
    return <Navigate to="/login" replace state={{ from: location }} />;
  } else {
    const redirect_url = window.localStorage.getItem('pre_redirect_url');
    if (redirect_url === location.pathname + location.search) {
      window.localStorage.removeItem('pre_redirect_url');
    } else if (redirect_url) {
      return <Navigate to={redirect_url} replace state={{ from: location }} />;
    }
  }
  return children;
};

const DocSearchVercelRedirect = () => {
  React.useEffect(() => {
    window.location.replace('https://mx2law.vercel.app/doc-search');
  }, []);

  return null;
};

const ExpertSearchVercelRedirect = () => {
  React.useEffect(() => {
    window.location.replace('https://mx2law.vercel.app/expert-search');
  }, []);

  return null;
};
