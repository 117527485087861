import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { injectPendoScript } from './inject-pendo-script';
import { initializePendo } from './initialize-pendo';
import { AuthContext } from '../../App';

declare global {
  interface Window {
    pendo?: {
      initialize: (initializeData?: object) => object;
      track: (event: string, params?: object) => void;
    };
  }
}

export const PendoContext = createContext<{ track: (event: string, eventParams?: object) => void }>({
  track: (event: string, eventParams?: object) => {},
});

export const PendoProvider = ({ children }: { children: React.ReactNode }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { userInfo } = useContext(AuthContext);

  useEffect(() => {
    injectPendoScript();
  }, []);

  useEffect(() => {
    if (userInfo) {
      initializePendo(
        {
          id: userInfo['email'],
          email: userInfo['email'],
          full_name: `${userInfo['given_name']} ${userInfo['family_name']}`,
        },
        {
          id: userInfo['email'],
          cognitoId: userInfo['sub'],
          name: userInfo['cognito:groups'].join(', '),
        }
      );
      setIsInitialized(true);
    }
  }, [userInfo]);

  const track = useCallback(
    (event: string, eventParams?: object) => {
      if (!window.pendo || !isInitialized) return;
      window.pendo.track(event, eventParams);
    },
    [isInitialized]
  );

  return <PendoContext.Provider value={{ track }}>{children}</PendoContext.Provider>;
};
