import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { TableauEmbed } from '@stoddabr/react-tableau-embed-live';
import { getTableauToken, getTableauDashboards } from '../interactors/site';

interface TableauDashaboards {
  tableau_link: string;
  name: string;
}

export default function HomePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryDashboard = searchParams.get('dashboard') || '';
  const [tableau_dashboards, setTableau_dashboards] = useState<Array<TableauDashaboards>>([]);
  const [tableau_name, setTableau_name] = useState<string>('');
  const [tableau_link, setTableau_link] = useState<string>('');
  const [tableau_token, setTableau_token] = useState<string>('');

  useEffect(() => {
    getTableauToken().then((info) => {
      setTableau_token(info.token);
    });
    getTableauDashboards().then((dashboards) => {
      setTableau_dashboards(dashboards);
      if (queryDashboard) {
        const found = dashboards.find((d) => d.name === queryDashboard);
        if (found) {
          setTableau_name(found.name);
          setTableau_link(found.tableau_link);
        }
      } else if (dashboards.length > 0) {
        setTableau_name(dashboards[0].name);
        setTableau_link(dashboards[0].tableau_link);
      }
    });
  }, []);

  const handleTableau_link = (ev) => {
    const found = tableau_dashboards.find((d) => d.name === ev.target.value);
    if (found) {
      setTableau_name(found.name);
      setTableau_link(found.tableau_link);
      setSearchParams({ dashboard: found?.name });
    }
  };
  return (
    <>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Typography variant="h3" color={'primary'} alignContent={'center'} sx={{ pl: 1, display: 'inline-flex' }}>
          Tableau
        </Typography>
      </Paper>
      <Card>
        <CardHeader
          title="Select the Dashsboard you are looking for."
          subheader={
            <TextField
              sx={{ mt: 2, minWidth: '200px' }}
              select
              onChange={handleTableau_link}
              label={tableau_dashboards.length ? 'Choose Tableau:' : 'Loading...'}
              SelectProps={{
                native: true,
              }}
              value={tableau_name}
            >
              {tableau_dashboards.map((tableau) => (
                <option value={tableau.name}>{tableau.name}</option>
              ))}
            </TextField>
          }
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          {tableau_token && tableau_link && <TableauEmbed sourceUrl={tableau_link} token={tableau_link.startsWith('https://public.tableau.com/') ? undefined : tableau_token} />}
        </CardContent>
      </Card>
    </>
  );
}
