import React from 'react';
import { useAuth } from '../App';
import CopyToken from '../components/CopyToken';
import { Tooltip, Typography, Button, Paper, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

export default function HomePage() {
  const { token, store, onLogout } = useAuth();
  return (
    <>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Typography variant="h3" color={'primary'} alignContent={'center'} sx={{ pl: 1, display: 'inline-flex' }}>
          Home
        </Typography>
      </Paper>
      <Card>
        <CardHeader title={store.firstName + ' ' + store.lastName} subheader={store.email} titleTypographyProps={{ variant: 'h5' }} />
        <CardContent>
          <CopyToken />
        </CardContent>
        <CardActions>
          <Tooltip title="Logout" placement="bottom">
            <Button aria-label="logout" color="info" onClick={onLogout} endIcon={<LogoutIcon />}>
              Logout
            </Button>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );
}
