import { OCRApiDocument, OCRApiDocumentTextractStatus } from '../../types/ocr_document';

import { progress_milestones_in_order } from './constants';

export const hasDocumentBeenOCRd = (doc?: OCRApiDocument) => {
  return doc?.metadata?.textract_results_status === OCRApiDocumentTextractStatus.SUCCEEDED;
};

export const getPercentDone = (doc: OCRApiDocument) => {
  const metadata = doc?.metadata;
  let progress = 0;
  if (!metadata) return progress;
  progress_milestones_in_order.forEach((milestone, index) => {
    if (metadata[milestone]) {
      progress = Math.floor(100 * ((index + 1) / progress_milestones_in_order.length));
      return false; // break out of loop
    }
    return true;
  });
  return progress;
};

export const calculateChecksum256 = async (file: Blob): Promise<string> => {
  const data = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const base64String = btoa(String.fromCharCode(...hashArray));
  return base64String;
};

const kilobyte = 1024;
const megabyte = Math.pow(kilobyte, 2);

export const showFileSize = (blob: Blob) => {
  const { size } = blob;
  const [comparator, label] = size > megabyte ? [megabyte, 'mb'] : [kilobyte, 'kb'];
  return `${(size / comparator).toFixed(1)}${label}`;
};
