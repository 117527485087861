import { APIUrl, getAPIHeaders } from './utils';
import { SearchFilter } from '../pages/search/convert-filters-to-es-query';

export const SearchApiBaseUrl = APIUrl.replace('api', 'search-api.api').concat('api/v1');

interface Matter {
  id: string;
  case_incident_date: string;
  case_open_date: string;
  case_type_name: string;
  principal_attorney_name: string;
  case_status: string;
  finance_closed_date: string;
  finance_status: string;
  created_date: string;
  last_modified_date: string;
  parent_office: string;
  litigation: string;
  matter_stage_read_only: string;
  case_name: string;
  case_display_name: string;
  reference_number: string;
}

export interface Document {
  id: string;
  created_by_id: string;
  created_date: string;
  detected_type: string;
  doc_author: string;
  matter: Matter;
  title: string;
  doc_file_type: string;
  doc_version_id: string;
  last_modified_date: string;
  name: string;
  ocr_detected_type: string;
  uploaded_date: string;
  first_page_content: string;
}

interface SearchData {
  nodes: Document[];
  total: number;
}

interface SearchApiResponse {
  success: boolean;
  data: SearchData;
}

interface SuggestApiResponse {
  success: boolean;
  suggestions: string[];
}

interface DocumentByDocumentNumberResponse {
  success: boolean;
  document: Record<string, unknown>;
}

export const searchDocuments = async (filters: SearchFilter[]): Promise<SearchData | null> => {
  const response = await fetch(`${SearchApiBaseUrl}/search`, {
    method: 'POST',
    headers: getAPIHeaders(),
    body: JSON.stringify({ filters, size: 32 }),
  });
  const result: SearchApiResponse = await response.json();
  if (!response.ok) throw result;
  return result.success ? result.data : null;
};

export const suggestValues = async (fieldName: string, value: string): Promise<string[] | null> => {
  const response = await fetch(`${SearchApiBaseUrl}/search/suggester/matter`, {
    method: 'POST',
    headers: getAPIHeaders(),
    body: JSON.stringify({ field: fieldName, value, size: 32 }),
  });
  const result: SuggestApiResponse = await response.json();
  if (!response.ok) throw result;
  return result.success ? result.suggestions : null;
};

export const getDocumentIdByDocumentNumber = async (documentNumber: string): Promise<string> => {
  const response = await fetch(`${SearchApiBaseUrl}/search/document_number/${documentNumber}`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  const result: DocumentByDocumentNumberResponse = await response.json();
  if (!response.ok || !result?.document?.id) throw result;
  return result.document.id;
};
