import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import TextField from '@mui/material/TextField';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { useAuth } from '../App';
import { Document, Page, pdfjs, Outline } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Box, Toolbar } from '@mui/material';
import { NotificationContext } from '../App';
import { getDocumentMetadata, getDocumentText } from '../interactors/documents';
import { FileAPIURL, getFileOrginal, getFileImage } from '../interactors/file';

pdfjs.GlobalWorkerOptions.workerSrc = 'lib/pdf.worker.min.mjs';

function highlightPattern(text: string, pattern: string) {
  return text.replace(new RegExp(pattern, 'gi'), (value: string) => `<mark>${value}</mark>`);
}

export default function Viewer() {
  const { token } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryEmbed = searchParams.get('embed') === 'true' ? true : false;
  const queryDocumentId = searchParams.get('doc') || '';
  const queryVersionId = searchParams.get('version') || '';
  const queryPageNumber = Number(searchParams.get('page') || '1');
  const queryHighlight = searchParams.get('highlight') || '';
  const { setToastMessage } = useContext(NotificationContext);

  const [numPages, setNumPages] = useState<number>();
  const [pageWidth, setPageWidth] = useState<number>(620);
  const [pageNumber, setPageNumber] = useState<number>(queryPageNumber);
  const [scaleLevel, setScaleLevel] = useState<number>(1);
  const [embed, setEmbed] = useState<Boolean>(queryEmbed);

  const [documentIdText, setDocumentIdText] = useState<string>(queryDocumentId);
  const [versionIdText, setVersionIdText] = useState<string>(queryVersionId);

  const [documentId, setDocumentId] = useState<string>(queryDocumentId);
  const [versionId, setVersionId] = useState<string>(queryVersionId);

  const [highlightText, setHighlightText] = useState<string>(queryHighlight);
  const [metadata, setMetadata] = useState<Object | null>(null);
  const [fileUrl, setFileUrl] = useState<string>('');
  const file = useMemo(() => ({ url: fileUrl }), [fileUrl]);
  const options = useMemo(
    () => ({
      httpHeaders: { authorization: `Bearer ${token}` },
    }),
    [token]
  );

  async function onDocumentLoadSuccess(page) {
    setNumPages(page.numPages);
    const pageObj = await page.getPage(1)
    console.log(pageObj)
    setPageWidth(pageObj.view[2])
  }

  const textRenderer = useCallback((textItem) => highlightPattern(textItem.str, highlightText), [highlightText]);

  const handleKeyDownDocument = (e) => {
    if (['Enter', 'Tab'].includes(e.key)) {
      handleUpdateDocumentId(documentIdText, versionIdText);
    }
  };

  const handleKeyDownVersion = (e) => {
    if (['Enter', 'Tab'].includes(e.key)) {
      handleUpdateDocumentId(documentIdText, versionIdText);
    }
  };

  const handleKeyDOwnHighlight = (e) => {
    if (['Enter', 'Tab'].includes(e.key)) {
      handleUpdateHighlight(highlightText);
    }
  };

  const handleUpdateDocumentId = async (doc: string, version: string | null) => {
    if (doc !== documentId) {
      setDocumentId(doc);
    }
    let url = `${FileAPIURL}file/${doc}/pdf-or-original`;
    if (version && version !== versionIdText) {
      setVersionIdText(version);
      setVersionId(version);
      url = `${FileAPIURL}file/${doc}/pdf-or-original?version_id=${version}`;
    }

    if (url === fileUrl) return;
    const document = await getDocumentMetadata(doc);
    setFileUrl(url);
    setMetadata(document.metadata);
    if (version === null) {
      version = document.metadata.version_id;
      setVersionIdText(version);
      setVersionId(version);
    }
    setSearchParams({ doc, page: '1', version, highlight: highlightText, embed });
  };

  const handleUpdatePageNumber = (page: number) => {
    setPageNumber(page);
    setSearchParams({ doc: documentId, version: versionId, page: page.toString(), highlight: highlightText, embed });
  };

  const handleUpdateHighlight = (highlight: string) => {
    setHighlightText(highlight);
    setSearchParams({ doc: documentId, version: versionId, page: pageNumber.toString(), highlight, embed });
  };

  const listAllPages = () => {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(<Paper elevation={3} sx={{ p: 2, mt: 2, maxWidth: (pageWidth + 100) * scaleLevel, margin: '8px auto'}}>
      <Page 
        pageNumber={i} 
        key={i} 
        inputRef={(ref) => {
          if (ref && pageNumber === i) {
            ref.scrollIntoView();
          }
        }}
        className="pdf-page" 
        scale={scaleLevel} 
        customTextRenderer={textRenderer} 
        loading={<CircularProgress 
        sx={{ ml: '40%', mt: 20, mb: 20 }} 
        color="secondary" 
        />}/>
      </Paper>);
    }
  
    return (
      <>
        {pages}
      </>
    );
  }
  useEffect(() => {
    handleUpdateDocumentId(documentIdText, versionIdText);
  }, [documentIdText, versionIdText]);

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Toolbar sx={{ justifyContent:'center' }} >
        <TextField
          type="text"
          value={documentIdText}
          onChange={(e) => setDocumentIdText(e.target.value)}
          placeholder="Enter document ID"
          onKeyDown={handleKeyDownDocument}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: 'background.paper',
            color: 'text.secondary',
            '& svg': {
              m: 1,
            },
            '& hr': {
              mx: 0.5,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleUpdateDocumentId(documentIdText, versionIdText)}>
                  <PlagiarismIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          type="text"
          value={versionIdText || ''}
          onChange={(e) => setVersionIdText(e.target.value)}
          placeholder="Enter Version ID"
          onKeyDown={handleKeyDownVersion}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: 'background.paper',
            color: 'text.secondary',
            '& svg': {
              m: 1,
            },
            '& hr': {
              mx: 0.5,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleUpdateDocumentId(documentIdText, versionIdText)}>
                  <PlagiarismIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          type="text"
          value={highlightText}
          onChange={(e) => setHighlightText(e.target.value)}
          placeholder="Highlight Text"
          onKeyDown={handleKeyDOwnHighlight}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: 'background.paper',
            color: 'text.secondary',
            '& svg': {
              m: 1,
            },
            '& hr': {
              mx: 0.5,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleUpdateHighlight(highlightText)}>
                  <PlagiarismIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <Toolbar sx={{ justifyContent:'center' }}>
        {numPages && (
          <Box>
            <IconButton onClick={() => setScaleLevel(scaleLevel - 0.5)}>
              <ZoomOutIcon />
            </IconButton>
            <TextField type="number" size="small" value={scaleLevel} onChange={(e) => setScaleLevel(Number(e.target.value))} placeholder="Enter Scale Level" sx={{ width: 70 }} />
            <IconButton onClick={() => setScaleLevel(scaleLevel + 0.5)}>
              <ZoomInIcon />
            </IconButton>
            {/* <Divider orientation="vertical" flexItem /> */}
            <IconButton onClick={() => handleUpdatePageNumber(1)} disabled={pageNumber === 1}>
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
            <IconButton onClick={() => handleUpdatePageNumber(Math.max(pageNumber - 1, 1))} disabled={pageNumber === 1}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <TextField
              type="number"
              size="small"
              value={pageNumber}
              onChange={(e) => handleUpdatePageNumber(Math.min(Number(e.target.value), numPages))}
              placeholder="Enter Page Number"
              sx={{ width: 70 }}
            />
            <TextField type="text" size="small" value={numPages} disabled={true} sx={{ width: 70 }} />
            <IconButton onClick={() => handleUpdatePageNumber(Math.min(pageNumber + 1, numPages))} disabled={pageNumber == numPages}>
              <KeyboardArrowRightIcon />
            </IconButton>
            <IconButton onClick={() => handleUpdatePageNumber(numPages)} disabled={pageNumber == numPages}>
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
        )}
      </Toolbar>
      <Paper elevation={2} sx={{ p: 2, mt: 2, overflowY: 'auto', height: '80%'}}>
        {fileUrl && file && (
          <Document 
            file={fileUrl}
            error={"Failed to load PDF file."}
            options={options}
            onLoadError={(err) => setToastMessage({ message: err, severity: 'error' })}
            onLoadSuccess={onDocumentLoadSuccess}
            className="pdf-document">
            <Outline /> 
            { listAllPages() }
            {/* <Page pageNumber={pageNumber} className="pdf-page" scale={scaleLevel} customTextRenderer={textRenderer} loading={<CircularProgress sx={{ ml: '45%', mt: 20, mb: 20 }} color="secondary" />} /> */}
          </Document>
        )}
      </Paper>
    </Paper>
  );
}
