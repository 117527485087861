import React, { FormEvent, KeyboardEvent, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';
import Conversation from './conversation';
import { Box, TextField, IconButton } from '@mui/material';

interface ChatInputProps {
  isMessageLoading: boolean;
  onSubmit: (message: string) => void;
  onAbortRequest: () => void;
}

export default function ChatInput({ isMessageLoading, onSubmit, onAbortRequest }: ChatInputProps) {
  const [message, setMessage] = useState<string>('');
  const isSubmitDisabled = !message || message.trim().length === 0 || isMessageLoading;

  const handleSubmit = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (!isSubmitDisabled) {
      onSubmit(message);
      setMessage('');
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', position: 'relative', mt: 4 }}>
      <TextField
        fullWidth
        multiline
        value={message}
        placeholder="Ask a follow up question"
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={isMessageLoading}
        sx={{
          '& .MuiInputBase-root': { pr: 7 },
          backgroundColor: 'background.paper',
        }}
        InputProps={{
          startAdornment: (
            <Box sx={{ ml: 1, mr: 2 }}>
              <Conversation />
            </Box>
          ),
          endAdornment: (
            <Box sx={{ position: 'absolute', right: 8, bottom: 8 }}>
              {isMessageLoading ? (
                <IconButton onClick={onAbortRequest} color="error">
                  <StopIcon />
                </IconButton>
              ) : (
                <IconButton disabled={isSubmitDisabled} type="submit" color="primary">
                  <SendIcon />
                </IconButton>
              )}
            </Box>
          ),
        }}
      />
    </Box>
  );
}
