import { PaletteMode } from '@mui/material';
import { grey } from '@mui/material/colors';

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: '#00355C',
          },
          secondary: {
            main: '#2e2e2e',
          },
          divider: '#B9B9B9',
          background: {
            default: '#fff',
            paper: '#ffffff',
            drawer: '#00355C',
            secondary: '#F9FCFD',
            icon: '#E8F1F8',
          },
          text: {
            primary: '#2e2e2e',
            secondary: '#115099',
            link: '#1685FC',
          },
          success: {
            main: '#004E89',
          },
          error: {
            main: '#b3261e',
          },
          warning: { main: '#FF6B35' },
        }
      : {
          // palette values for dark mode
          //d6c602
          primary: {
            main: '#ddd',
          },
          secondary: {
            main: '#111111',
          },
          divider: '#2e2e2e',
          background: {
            default: '#000000',
            paper: '#111111',
            drawer: '#111111',
            icon: grey[800],
          },
          text: {
            primary: '#ddd',
            secondary: grey[400],
            link: '#eee',
            success: '#85CAFF',
            error: '#EA5B53',
            info: '#d6c602',
          },
          success: {
            main: '#85CAFF',
          },
          error: {
            main: '#EA5B53',
          },
          info: {
            main: '#d6c602',
          },
        }),
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        // @ts-ignore: theme is a bit dynamic
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.main,
          boxShadow: 'none',
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        // @ts-ignore: theme is a bit dynamic
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: () => ({
          borderRadius: '6px',
          padding: '1rem',
        }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: () => ({
          borderRadius: '6px',
        }),
      },
    },
    MuiBox: {
      styleOverrides: {
        root: () => ({
          borderRadius: '6px !important',
        }),
      },
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
});
